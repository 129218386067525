export const baseUrl = "https://api.bosoninternationalschool.com/api/";
export const MetabaseUrl = "https://bosoninternationalschool.com/";
// export const baseUrl = "https://api.websitesolution.cloud/api/";
// export const MetabaseUrl = "https://www.websitesolution.cloud.com/";

  // ---------------------Developed by Disha Halkara-------------------------------
  // ---------------------Date: 18-07-2024-----------------------------------------
  // You can find here all the logic for get / post method
export const GETAPI = async (path) => {
  try {
    const response = await fetch(`${baseUrl}${path}`, {
      method: "POST",
    });
    const data = await response.json();

    if (data && data.status === "Success") {
      return data.data;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error);
  }
};



export const POSTAPI = async (path, id) => {
  const formData = new FormData();
  formData.append("Alias", id);

  try {
      const response = await fetch(`${baseUrl}${path}`, {
          method: "POST",
          body: formData,
          headers: {
              Accept: "application/json", // Expecting JSON response
          },
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.data; // Assuming data contains the JSON response object
  } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Handle errors or return null based on your application's logic
  }
};


export const MetaPOSTAPI = async (path, id) => {
  const formData = new FormData();
  formData.append("Alias", id);

  try {
      const response = await fetch(`${baseUrl}${path}`, {
          method: "POST",
          body: formData,
          headers: {
              Accept: "application/json", // Expecting JSON response
          },
      });

      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data.data; // Assuming data contains the JSON response object
  } catch (error) {
      console.error("Error fetching data:", error);
      return null; // Handle errors or return null based on your application's logic
  }
};