import React, {
  useEffect,
  useState,
} from 'react';

import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import {
  GETAPI,
  MetabaseUrl,
  MetaPOSTAPI,
} from '../Redux/Service';

const News = () => {
    const [Data1, setData1] = useState([]);

    // Fetch data from API
    const getView = async () => {
        try {
            const result = await GETAPI('list-announcement');
            if (result) {
                setData1(result);
                console.log(Data1)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch meta information
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [ogUrl, setOgUrl] = useState('');

    const getMeta = async () => {
        try {
            const result = await MetaPOSTAPI('list-meta-details', 'announcements');
            setMetaDescription(result[0]?.meta_description || 'Default description');
            setMetaKeywords(result[0]?.meta_keyword || 'Default keywords');
            setMetaTitle(result[0]?.meta_title || 'Boson International School');
            setOgImage(result[0]?.og_img || `${MetabaseUrl}assets/images/logo.svg`);
            setOgUrl(result[0]?.og_url || MetabaseUrl);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getView();
        getMeta();
    }, []);

    // Update meta tags in document head
    useEffect(() => {
        document.title = metaTitle;

        const metaTags = [
            { name: 'description', content: metaDescription },
            { name: 'keywords', content: metaKeywords },
            { property: 'og:title', content: metaTitle },
            { property: 'og:description', content: metaDescription },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: ogUrl },
            { property: 'og:image', content: ogImage },
            { property: 'og:image:alt', content: metaTitle }
        ];

        metaTags.forEach(tag => {
            const metaTag = document.createElement('meta');
            if (tag.property) {
                metaTag.setAttribute('property', tag.property);
            } else {
                metaTag.setAttribute('name', tag.name);
            }
            metaTag.setAttribute('content', tag.content);
            document.head.appendChild(metaTag);
        });
    }, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return (
        <>
            <Header />
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        <h1>Announcements</h1>
                        <p>Boson International School is proud to unveil exciting new updates across our campus</p>
                    </div>
                </div>
            </section>
            {/* <!-- CARD --> */}
            <section className="wrapper" >
                <div className="container">
                    <div className="row">

                    {Data1.map((item, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="card">
                                <div className="card-body">
                                        {/*  <figure><img src="assets/images/icons/abouticon1.svg" alt="" /></figure>
                           <figure><img src={`${MetabaseUrl}api/${item.NewsPic}`} alt="" /></figure> */}
                                    <h5>{capitalizeFirstLetter(item.Title)}</h5>
                                    <p  dangerouslySetInnerHTML={{ __html: item.Details }} />
                                    <p>{new Date(item.Date).toLocaleDateString('en-US')}</p>  
                                     {/* <a href="#" className="btn exploremoredes">Explore More</a> */}
                                </div>
                            </div>
                        </div>
                      
                    ))}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default News
