import React, { Component } from "react";
import ErrorFallback from "./ErrorFallback";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // You can log the error to an error reporting service
    console.error(error);
    console.error(info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI to display when an error occurs
      return <ErrorFallback />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
