import React, {
  useEffect,
  useState,
} from 'react';

import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import {
  GETAPI,
  MetabaseUrl,
  MetaPOSTAPI,
} from '../Redux/Service';
import { Link } from 'react-router-dom';

const Image = () => {
    const [Data1, setData1] = useState([]);

    // Fetch data from API
    const getView = async () => {
        try {
            const result = await GETAPI('list-images');
            if (result) {
                setData1(result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch meta information
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [ogUrl, setOgUrl] = useState('');

    const getMeta = async () => {
        try {
            const result = await MetaPOSTAPI('list-meta-details', 'photo-gallery');
            setMetaDescription(result[0]?.meta_description || 'Default description');
            setMetaKeywords(result[0]?.meta_keyword || 'Default keywords');
            setMetaTitle(result[0]?.meta_title || 'Boson International School');
            setOgImage(result[0]?.og_img || `${MetabaseUrl}assets/images/logo.svg`);
            setOgUrl(result[0]?.og_url || MetabaseUrl);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getView();
        getMeta();
    }, []);

    // Update meta tags in document head
    useEffect(() => {
        document.title = metaTitle;

        const metaTags = [
            { name: 'description', content: metaDescription },
            { name: 'keywords', content: metaKeywords },
            { property: 'og:title', content: metaTitle },
            { property: 'og:description', content: metaDescription },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: ogUrl },
            { property: 'og:image', content: ogImage },
            { property: 'og:image:alt', content: metaTitle }
        ];

        metaTags.forEach(tag => {
            const metaTag = document.createElement('meta');
            if (tag.property) {
                metaTag.setAttribute('property', tag.property);
            } else {
                metaTag.setAttribute('name', tag.name);
            }
            metaTag.setAttribute('content', tag.content);
            document.head.appendChild(metaTag);
        });
    }, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

   const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return (
        <>
            <Header />
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        <h1>Image Gallery</h1>
                        <p>A stunning aerial shot showcasing the sprawling campus of Boson International School. The image captures the main academic buildings, sports facilities, and lush green lawns.</p>
                    </div>
                </div>
            </section>
            <section className="wrapper" style={{ background: "#00325a0a" }}>
    <div className="container">
        {Data1.map((item, index) => {
            let parsedImages = [];
            try {
                parsedImages = JSON.parse(item.Images);
            } catch (e) {
                console.error('Error parsing images:', e);
            }

            return (
                <div className="row" style={{ display: 'flex' }} key={index}>
                    <h3>{capitalizeFirstLetter(item.Title)}</h3>
                    {parsedImages.map((image, imgIndex) => (
                        <div className="col-md-4 mb-4" key={imgIndex}>
                            <div className="details--page--imggrid item gallery-item overlay">
                                <div className="gallery-images">
                                    <Link to={`${MetabaseUrl}api/${image}`} data-fancybox="gallery">
                                        <img
                                            src={`${MetabaseUrl}api/${image}`}
                                            alt={`${item.Title} - ${imgIndex}`}
                                            className="img-fluid"
                                        />
                                        <div className="overlay-content"> 
                                            <h3>{capitalizeFirstLetter(item.Title)}</h3>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
        })}
    </div>
</section>

            {/* <section className="wrapper" style={{ background: "#00325a0a" }}>
                <div className="container">
                 
                        {Data1.map((item, index) => {
                            let parsedImages = [];
                            try {
                                parsedImages = JSON.parse(item.Images);
                            } catch (e) {
                                console.error('Error parsing images:', e);
                            }

                            return (
                                <div className="row" style={{display:'flex'}}>
                                     <h3>{capitalizeFirstLetter(item.Title)}</h3>
                                            {parsedImages.map((image, imgIndex) => (
                                <div className="col-md-4 mb-4" key={index}>
                                    <div className="details--page--imggrid item gallery-item overlay">
                                        <div className="gallery-images">
                                    <Link to={`${MetabaseUrl}api/${image}`} data-fancybox="gallery">
                                                <img
                                                    key={imgIndex}
                                                    src={`${MetabaseUrl}api/${image}`}
                                                    alt={`${item.Title} - ${imgIndex}`}
                                                    className="img-fluid"
                                                />
                                        
                                       
                                        <div className="overlay-content"> 
                                        <h3>{capitalizeFirstLetter(item.Title)}</h3>
                                 </div>
                                 </Link>
                                 </div>
                                    </div>
                                </div>
                                            ))}
                    </div>
                            );
                        })}
                </div>
            </section> */}
            <Footer />
        </>
    );
};

export default Image;

