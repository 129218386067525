import { configureStore } from "@reduxjs/toolkit";
import postsSlice from "./Contact";
  // ---------------------Developed by Disha Halkara-------------------------------
  // ---------------------Date: 18-07-2024-----------------------------------------
  // ---------------------Store----------------------------------------------------

const store = configureStore({
  reducer: {
    api: postsSlice,

  },
});

export default store;
