import React from "react";

function ErrorFallback() {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Oops! Something went wrong</h1>
      <p style={styles.message}>
        We're sorry, but an unexpected error occurred. Please try again later.
      </p>
    </div>
  );
}

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  message: {
    fontSize: "16px",
  },
};

export default ErrorFallback;
