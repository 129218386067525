import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { toast } from 'react-toastify';
import { FaFileAlt } from "react-icons/fa";
import axios from 'axios';
import { baseUrl, MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';
// ---------------------Developed by Disha Halkara-------------------------------
// ---------------------Date: 18-07-2024-----------------------------------------
const TC = () => {
    const [Name, setName] = useState();
    const [Year, setYear] = useState();
    const [No, setNo] = useState();
    const [AdmissionNo, setAdmissionNo] = useState();
    const [TcData, setTcData] = useState([]);
    const [IsTcData, setIsTcData] = useState(false);
    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    // ---------------------Handle Submit for TC form-----------------------------------------
    const handleReset = () => {
       
        setName('');
        setYear('');
        setNo('');
        setAdmissionNo('');
        setIsTcData(false);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('p_StudentName', Name || "");
        formData.append('p_TCYear', Year || "");
        formData.append('p_TCNo', No || "");
        formData.append('p_AdmissionNO', AdmissionNo || "");

        try {
            const response = await axios.post('https://api.bosoninternationalschool.com/api/list_tc', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log(response.data);
            if (response.data.data.length > 0) {
                setTcData(response.data.data)
                setIsTcData(true)
            }
            else {
                toast.error("Data Not Found");
            }
            if (response.data.status === "Success") {
                toast.success("Request Sent Successfully");
                // handleReset();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Failed to send message: ", error);
            toast.error("An error occurred. Please try again.");
        }

    };

    // ---------------------Download file / Image----------------------------------------
    const handleDownload = (fileName) => {
        const link = document.createElement("a");
        link.href = fileName;
        link.target = "_blank";
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



     // Dynamic Meta Tags By Disha Halkara 29-7-24
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [ogImage, setOgImage] = useState('');
  const [ogUrl, setOgUrl] = useState('');
  const getMeta = async () => {
    try {
        const result = await MetaPOSTAPI('list-meta-details', 'tc');
     
        // Assume result contains the required meta data
        setMetaDescription(result[0].meta_description || 'Default description');
        setMetaKeywords(result[0].meta_keyword || 'Default keywords');
        setMetaTitle(result[0].meta_title || 'Boson International School');
        setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
        setOgUrl(result[0].og_url || MetabaseUrl);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  // Update meta tags based on state
  useEffect(() => {
    window.scrollTo(0, 0);

    // Update document title
    document.title = metaTitle;

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create and append meta tag for description
    const metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.name = 'description';
    metaDescriptionTag.content = metaDescription;
    document.head.appendChild(metaDescriptionTag);

    // Create and append meta tag for keywords
    const metaKeywordsTag = document.createElement('meta');
    metaKeywordsTag.name = 'keywords';
    metaKeywordsTag.content = metaKeywords;
    document.head.appendChild(metaKeywordsTag);

    // Create and append Open Graph meta tags
    const metaTags = [
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: ogUrl },
        { property: 'og:image', content: ogImage },
        { property: 'og:image:alt', content: metaTitle }
    ];

    metaTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.property = tag.property;
        metaTag.content = tag.content;
        document.head.appendChild(metaTag);
    });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

  
  useEffect(() => {

    getMeta();
  }, []);
    return (
        <>
            <Header />
            {/* <!-- Breadcamp SECTION --> */}
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        <h6>Transfer Certificate (TC)</h6>
                        <h1>Requesting a TC</h1>
                        <p>Boson International School issues Transfer Certificates upon request, documenting a student’s academic record and conduct during their tenure at our institution.</p>
                    </div>
                </div>
            </section>
            <section className="wrapper" style={{ "background": "#00325a17" }}>
                <div className="container">
                    <div className="row wrapperformbac">
                        <div className="col-md-5">
                            <div className="wrapq--abouttcform">
                                <figure><img src="assets/images/tcimage.png" alt="" /></figure>
                                <div className="callabutop">
                                    <div className="callnow-wrapp">
                                        <img src="assets/images/icons/callabt.svg" alt="" />
                                        <span>
                                            <p>Call Now</p>
                                            <p><a href="">+91 7581-082132</a></p></span>
                                    </div>
                                    <div className="callnow-wrapp">
                                        <img src="assets/images/icons/emailabt.png" alt="" />
                                        <span>
                                            <p>Mail Us</p>
                                            <p><a href="">bosonschool@gmail.com</a></p></span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-7">
                            <div className="form-section">
                                <form onSubmit={handleSubmit} onReset={handleReset}>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label for="firstName">Enter your name</label>
                                            <input value={Name} type="text" className="form-control" id="firstName" placeholder="Enter you Name" onChange={(event) => {
                                                setName(event.target.value);
                                            }} />
                                        </div>
                                        <div className="form-group">
                                            <label for="lastName">Enter TC Year</label>
                                            <input value={Year} type="text" className="form-control" id="lastName" placeholder="Enter you TC Year" onChange={(event) => {
                                                setYear(event.target.value);
                                            }} />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label for="studentName">Enter TC No.</label>
                                            <input value={No} onChange={(event) => {
                                                setNo(event.target.value);
                                            }} type="text" className="form-control" id="studentName" placeholder="Enter TC No" />
                                        </div>
                                        <div className="form-group">
                                            <label for="tcYear">Enter Admission No</label>
                                            <input value={AdmissionNo} onChange={(event) => {
                                                setAdmissionNo(event.target.value);
                                            }} type="text" className="form-control" id="tcYear" placeholder="Ente Admission No" />
                                        </div>
                                    </div>
                                    <div className="btntc">
                                        <button type="submit" className="">FIND TC</button>
                                        <button type="reset" className="resetform">RESET</button>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                    <div className='wrapperformbac'>
                        {IsTcData ?
                            <table className="styled-table">
                                <thead>
                                    <tr>
                                    <th>S No</th>
                                        <th>TC No</th>
                                        <th>Student Name</th>
                                        <th>Parent Name</th>
                                        <th>Admission NO</th>
                                        <th>Date</th>
                                        <th>TC Year</th>
                                        <th>Last Class</th>
                                        <th>Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {TcData.map((user, index) => (
                                        <tr key={user.TCNo}>
                                            <td>{index+1}</td>
                                            <td>{user.TCNo}</td>
                                            <td>{capitalizeFirstLetter(user.StudentName)}</td>
                                            <td>{capitalizeFirstLetter(user.ParentName)}</td>
                                            <td>{user.AdmissionNO}</td>
                                            <td>{new Date(user.Date).toLocaleDateString('en-GB')}</td>
                                            <td>{user.TCYear}</td>
                                            <td>{user.LastClass}</td>
                                            <td>
                                                <div className="image-link"

                                                    style={{
                                                        color: "black",
                                                        fontSize: "22px",
                                                        cursor: "pointer",
                                                        padding: "4px",
                                                        borderRadius: "6px",
                                                        fontWeight: "bold",
                                                        width: "5rem",
                                                        textAlign: "center",
                                                    }}
                                                    onClick={() => handleDownload((`${MetabaseUrl}api/${user.FullImageUrl}`))}
                                                >
                                                    {user.FullImageUrl ? (
                                                        <a style={{ color: "black", textDecoration: "underline" }}>
                                                            <FaFileAlt />
                                                        </a>
                                                    ) : (
                                                        " "
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            : ""}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TC
