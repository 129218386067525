import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {baseUrl} from './Service'

const initialState = {
  posts: [],
  status: "idle",
  error: null,
};
  // ---------------------Developed by Disha Halkara-------------------------------
  // ---------------------Date: 18-07-2024-----------------------------------------
  // ---------------------Contact Api Definition-----------------------------------
const apiUrl = `${baseUrl}add-contact`; // Replace with your API endpoint URL

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async (body) => {
  try {
    const response = await axios.post(apiUrl, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data; // Assuming response contains data directly
  } catch (error) {
    // Handle errors
    console.error("Error fetching posts:", error);
    throw error;
  }
});

const postsSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    // You can add additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.posts = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default postsSlice.reducer;
