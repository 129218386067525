import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import { MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';

const Books = () => {
  
  // Dynamic Meta Tags By Disha Halkara 29-7-24
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [ogImage, setOgImage] = useState('');
  const [ogUrl, setOgUrl] = useState('');
  const getMeta = async () => {
    try {
        const result = await MetaPOSTAPI('list-meta-details', 'books');
     
        // Assume result contains the required meta data
        setMetaDescription(result[0].meta_description || 'Default description');
        setMetaKeywords(result[0].meta_keyword || 'Default keywords');
        setMetaTitle(result[0].meta_title || 'Boson International School');
        setOgImage(result[0].og_img ||`${MetabaseUrl}assets/images/logo.svg`);
        setOgUrl(result[0].og_url || MetabaseUrl);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};
  // Update meta tags based on state
  useEffect(() => {
    window.scrollTo(0, 0);

    // Update document title
    document.title = metaTitle;

    // Remove existing meta tags if any
    const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
    existingMetaTags.forEach(tag => tag.remove());

    // Create and append meta tag for description
    const metaDescriptionTag = document.createElement('meta');
    metaDescriptionTag.name = 'description';
    metaDescriptionTag.content = metaDescription;
    document.head.appendChild(metaDescriptionTag);

    // Create and append meta tag for keywords
    const metaKeywordsTag = document.createElement('meta');
    metaKeywordsTag.name = 'keywords';
    metaKeywordsTag.content = metaKeywords;
    document.head.appendChild(metaKeywordsTag);

    // Create and append Open Graph meta tags
    const metaTags = [
        { property: 'og:title', content: metaTitle },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: ogUrl },
        { property: 'og:image', content: ogImage },
        { property: 'og:image:alt', content: metaTitle }
    ];

    metaTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        metaTag.property = tag.property;
        metaTag.content = tag.content;
        document.head.appendChild(metaTag);
    });

}, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);

  
  useEffect(() => {

    getMeta();
  }, []);
  const bookList = [
    { className: 'NURSERY', books: [
      { number: 1, name: 'Building Block', publisher: 'Sky Books' },
      { number: 2, name: 'Hindi', publisher: 'Sky Books' }
    ]},
    { className: 'KG I', books: [
      { number: 1, name: 'Building Block', publisher: 'Sky Books' },
      { number: 2, name: 'Hindi', publisher: 'Sky Books' }
    ]},
    { className: 'KG II', books: [
      { number: 1, name: 'Building Block', publisher: 'Sky Books' },
      { number: 2, name: 'Hindi', publisher: 'Sky Books' }
    ]},
    { className: 'CLASS - I', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 5, name: 'Double click', publisher: 'Primse Rose' },
      { number: 6, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 7, name: 'Science', publisher: 'Prachi' },
      { number: 8, name: 'Lekhni', publisher: 'School Books' },
      { number: 9, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 10, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 11, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - II', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 5, name: 'Double click', publisher: 'Primse Rose' },
      { number: 6, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 7, name: 'Science', publisher: 'Prachi' },
      { number: 8, name: 'Lekhni', publisher: 'School Books' },
      { number: 9, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 10, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 11, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - III', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 5, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 6, name: 'Science', publisher: 'Prachi' },
      { number: 7, name: 'Lekhni', publisher: 'School Books' },
      { number: 8, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 9, name: 'Double Click', publisher: 'Primse Rose' },
      { number: 10, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 11, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - IV', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 5, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 6, name: 'Science', publisher: 'Prachi' },
      { number: 7, name: 'Lekhni', publisher: 'School Books' },
      { number: 8, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 9, name: 'Double Click', publisher: 'Primse Rose' },
      { number: 10, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 11, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - V', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 5, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 6, name: 'Science', publisher: 'Prachi' },
      { number: 7, name: 'Lekhni', publisher: 'School Books' },
      { number: 8, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 9, name: 'Double Click', publisher: 'Primse Rose' },
      { number: 10, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 11, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - VI', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 5, name: 'Science', publisher: 'Prachi' },
      { number: 6, name: 'Vyakaran Vyom', publisher: 'Mega' },
      { number: 7, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 8, name: 'Sanskrit Vyom', publisher: 'Anand' },
      { number: 9, name: 'Double Click', publisher: 'Prime Rose' },
      { number: 10, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 11, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 12, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - VII', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
      { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
      { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
      { number: 4, name: 'Perfect Mathematics', publisher: 'Dhruv' },
      { number: 5, name: 'Science', publisher: 'Prachi' },
      { number: 6, name: 'Vyakaran Vyom', publisher: 'Mega' },
      { number: 7, name: 'So. Science - World Explorers', publisher: 'Rohan' },
      { number: 8, name: 'Sanskrit Vyom', publisher: 'Anand' },
      { number: 9, name: 'Double Click', publisher: 'Prime Rose' },
      { number: 10, name: 'Knowledge world', publisher: 'Crystal' },
      { number: 11, name: 'joy of cursive writing', publisher: 'Silver line' },
      { number: 12, name: 'Art Castle', publisher: '10 Pointer' }
    ]},
    { className: 'CLASS - VIII', books: [
      { number: 1, name: 'Oakwood English', publisher: 'Souvenir' },
    { number: 2, name: 'Hello Grammar', publisher: 'Sparrow Education' },
    { number: 3, name: 'Hindi Diwas', publisher: 'Student Selection' },
    { number: 4, name: 'Perfect Mathematics', publisher: 'Dhruv' },
    { number: 5, name: 'Science', publisher: 'Prachi' },
    { number: 6, name: 'Vyakaran Vyom', publisher: 'Mega' },
    { number: 7, name: 'So. Science - World Explorers', publisher: 'Rohan' },
    { number: 8, name: 'Sanskrit Vyom', publisher: 'Anand' },
    { number: 9, name: 'Double Click', publisher: 'Prime Rose' },
    { number: 10, name: 'Knowledge world', publisher: 'Crystal' },
    { number: 11, name: 'joy of cursive writing', publisher: 'Silver line' },
    { number: 12, name: 'Art Castle', publisher: '10 Pointer' }
  ]}
];
let serialNumber = 1; // Initialize the serial number

  return (
    <>
      <Header />
      {/* <!-- Breadcamp SECTION --> */}
      <section className="breadcampsection">
        <div className="container">
          <div className="Breadcamp--text">
            {/* <h6>Infrastructure</h6> */}
            <h1>Books Detail</h1>
            <h5 style={{ color: 'white' }}>Declaration</h5><p>
              We have gone through the contents of the books prescided by the school and own the responsbility that the books are not having any objectionble content and we follow the CBSE (NCERT) curriculam.

              PRINCIPAL & MANAGEMENT</p>
          </div>
        </div>
      </section>
      {/* <!-- Table --> */}
      <section className="wrapper">
        <div className="container">
          <div className="row">

            <div className="tavle--mainwrap">
              <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sr. no</th>
                  <th scope="col">Class</th>
                  <th scope="col">Book Number</th>
                  <th scope="col">Book Name</th>
                  <th scope="col">Publisher</th>
                </tr>
              </thead>
              {/* <tbody>
                {bookList.flatMap((cls, classIndex) => [
                
                  classIndex > 0 && <tr key={`hr-${classIndex}`}><td colSpan="5"><hr/></td></tr>,
                  ...cls.books.map((book, bookIndex) => (
                    <tr key={`${classIndex}-${bookIndex}`}>
                      <td>{serialNumber++}</td>
                      <td>{cls.className}</td>
                      <td>{book.number}</td>
                      <td>{book.name}</td>
                      <td>{book.publisher}</td>
                    </tr>
                  ))
                ])}
              </tbody> */}

              <tbody>
                {bookList.flatMap((cls, classIndex) => [
                  // Class heading row
                  classIndex > 0 && (
                    <tr key={`hr-${classIndex}`}>
                      <td colSpan="5">
                        <hr/>
                      </td>
                    </tr>
                  ),
                  <tr key={`heading-${classIndex}`}>
                    <td colSpan="5"  className="class-heading">
                      <center>
                      {cls.className}
                      </center>
                    </td>
                  </tr>,
                  ...cls.books.map((book, bookIndex) => (
                    <tr key={`${classIndex}-${bookIndex}`}>
                      <td>{serialNumber++}</td> {/* Increment the serial number */}
                      <td>{cls.className}</td>
                      <td>{book.number}</td>
                      <td>{book.name}</td>
                      <td>{book.publisher}</td>
                    </tr>
                  ))
                ])}
              </tbody>

              </table>
            </div>

          </div>
          <center>
            <div className="col-md-8">
              <div>
                <figure>
                  <img src="assets/images/Declaration.png" alt="" />
                </figure>
              </div>
            </div>
          </center>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Books
