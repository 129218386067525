import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { baseUrl, GETAPI, MetabaseUrl, MetaPOSTAPI } from '../Redux/Service';
import { Link } from 'react-router-dom';

const Video = () => {
    const [youtubeVideos, setYouTubeVideos] = useState([]);
    const [simpleVideos, setSimpleVideos] = useState([]);
    // Dynamic Meta Tags By Disha Halkara 29-7-24
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [ogUrl, setOgUrl] = useState('');
    const getMeta = async () => {
        try {
            const result = await MetaPOSTAPI('list-meta-details', 'video-gallery');

            // Assume result contains the required meta data
            setMetaDescription(result[0].meta_description || 'Default description');
            setMetaKeywords(result[0].meta_keyword || 'Default keywords');
            setMetaTitle(result[0].meta_title || 'Boson International School');
            setOgImage(result[0].og_img || `${MetabaseUrl}assets/images/logo.svg`);
            setOgUrl(result[0].og_url || MetabaseUrl);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    // Update meta tags based on state
    useEffect(() => {
        window.scrollTo(0, 0);

        // Update document title
        document.title = metaTitle;

        // Remove existing meta tags if any
        const existingMetaTags = document.querySelectorAll('meta[name="description"], meta[name="keywords"], meta[property^="og"]');
        existingMetaTags.forEach(tag => tag.remove());

        // Create and append meta tag for description
        const metaDescriptionTag = document.createElement('meta');
        metaDescriptionTag.name = 'description';
        metaDescriptionTag.content = metaDescription;
        document.head.appendChild(metaDescriptionTag);

        // Create and append meta tag for keywords
        const metaKeywordsTag = document.createElement('meta');
        metaKeywordsTag.name = 'keywords';
        metaKeywordsTag.content = metaKeywords;
        document.head.appendChild(metaKeywordsTag);

        // Create and append Open Graph meta tags
        const metaTags = [
            { property: 'og:title', content: metaTitle },
            { property: 'og:description', content: metaDescription },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: ogUrl },
            { property: 'og:image', content: ogImage },
            { property: 'og:image:alt', content: metaTitle }
        ];

        metaTags.forEach(tag => {
            const metaTag = document.createElement('meta');
            metaTag.property = tag.property;
            metaTag.content = tag.content;
            document.head.appendChild(metaTag);
        });

    }, [metaDescription, metaKeywords, metaTitle, ogImage, ogUrl]);


    useEffect(() => {

        getMeta();
    }, []);
    // ---------------------Accessing the data through API call-------------------------------
    const getView = async () => {
        try {
            const result = await GETAPI('list-videos');
            if (result && result) {
                // Separate data based on type
                const youtube = [];
                const simple = [];

                result.forEach(item => {
                    const parsedVideos = JSON.parse(item.Videos);
                    if (item.type === 'youtubevideo') {
                        youtube.push({ Title: item.Title, Videos: parsedVideos });
                    } else if (item.type === 'simplevideo') {
                        simple.push({ Title: item.Title, Videos: parsedVideos });
                    }
                });

                setYouTubeVideos(youtube);
                setSimpleVideos(simple);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getView();
    }, []);
    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    // ---------------------Base URL for the Gallery-------------------------------


    return (
        <>
            <Header />
            {/* <!-- Breadcamp SECTION --> */}
            <section className="breadcampsection">
                <div className="container">
                    <div className="Breadcamp--text">
                        <h1>Video Gallery</h1>
                        <p>A stunning aerial shot showcasing the sprawling campus of Boson International School. The image captures the main academic buildings, sports facilities, and lush green lawns.</p>
                    </div>
                </div>
            </section>
            <section className="wrapper" style={{ background: '#00325a0a' }}>
                <div className="container">
                    {/* Render YouTube Videos */}

                    {youtubeVideos.map((data, index) => (
                        <>
                            <div className="row" key={index}>
                                <div className="main-headwrap main-headwrapflex">

                                    <h3>{data.Title}</h3>

                                </div>
                                {data.Videos.map((videoId, index) => (
                                    <div className="col-md-6" key={index}>

                                        <div className="details--page--imggrid item gallery-item overlay">
                                            <div className="gallery-images">
                                                <Link to={`https://www.youtube.com/embed/${videoId}`} data-fancybox="gallery">

                                                    <iframe
                                                        width="100%"
                                                        height="315"
                                                        src={`https://www.youtube.com/embed/${videoId}`}
                                                        title="YouTube video player"
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        referrerPolicy="strict-origin-when-cross-origin"
                                                        allowFullScreen
                                                    ></iframe>
                                                    <div className="overlay-content">
                                                        <h3>{capitalizeFirstLetter(data.Title)}</h3>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>

                    ))}

                    {/* Render Simple Videos */}
                    {simpleVideos.map((data, index) => (
                        <>
                            <div className="row" key={index}>
                                <div className="main-headwrap main-headwrapflex">

                                    <h3>{data.Title}</h3>
                                </div>

                                {data.Videos.length > 0 ? (
                                    data.Videos.map((videoUrl, index) => (
                                        <div className="col-md-6" key={index}>
                                            <div className="details--page--imggrid item gallery-item overlay">
                                                <div className="gallery-images">
                                                    <Link to={`${MetabaseUrl}api/${videoUrl}`} data-fancybox="gallery">
                                                        <video loop controls width="100%" height="480">
                                                            <source type="video/mp4" src={`${MetabaseUrl}api/${videoUrl}`} />
                                                        </video>
                                                        <div className="overlay-content">
                                                            <h3>{capitalizeFirstLetter(data.Title)}</h3>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No videos available</p>
                                )}


                            </div>
                        </>

                    ))}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Video;







